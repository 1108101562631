import React from 'react';
import Heading from '@tds/core-heading';
import Text from '@tds/core-text';
import Box from '@tds/core-box';
import Button from '@tds/core-button';
import Paragraph from '@tds/core-paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Container from '../../components/Container';
import { Link } from '../../styles/elements';
import LinkTds from '@tds/core-link'
import { useLocation } from 'react-router-dom';

const LandingPage = ({
  match: {
    params: { locale },
  },
}) => {
  const [t, i18next] = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const ListItem = styled.span`
    font-weight: bold;
`;
  return (
    <Container>
      <Box between={3} inset={3}>
        <Heading level="h2">{t('LANDING_PAGE_TITLE')}</Heading>
        <Text>{t('LANDING_PAGE_MSG')}</Text>
        <Text>{t('LANDING_PAGE_MSG_1')}</Text>
        <Text>{t('LANDING_PAGE_MSG_2')}</Text>
            
        <ol>
          <li><ListItem>{t('LANDING_PAGE_MSG_ORDER_1_HEADING')}</ListItem> {t('LANDING_PAGE_MSG_ORDER_1')}</li>
          <li><ListItem>{t('LANDING_PAGE_MSG_ORDER_3_HEADING')}</ListItem>{t('LANDING_PAGE_MSG_ORDER_3')}</li>
        </ol>
        <Text>{t('LANDING_PAGE_MSG_3')}</Text>
        <Text>{t('LANDING_PAGE_MSG_4')}</Text>
        <Paragraph>
        {t('REFER_DOCUMENT_TEXT')} <LinkTds href={t('REFER_DOCUMENT_LINK_HREF')}>{t('REFER_DOCUMENT_LINK')}</LinkTds>
        </Paragraph>
        <br />
        <Box>
          <Link to={`/${i18next.language}/registration-form?${query}`}>
            <Button>{t('LANDING_PAGE_BTN_TXT')}</Button>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default LandingPage;
