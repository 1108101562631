import React from 'react';
import { ExpandCollapse } from '@tds/core-expand-collapse';

import Box from '@tds/core-box';
import Heading from '@tds/core-heading';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { NormalText } from '../../styles/elements';
import { fieldNames } from '../../config/constants';

const Summary = ({ stepData }) => {
  const [t] = useTranslation();
  const { agree, ...updatedStepData} = stepData || {};
  
  const {
    legalBusinessName,
    firstName,
    lastName,
    email,
    mobileNumber,
    title,
    plan,
    term,
    offer,
    eligibleEmployees,
    province,
    preferredLanguage,
    additionalEmployee,
    noOfEmployees,
    totalPerMonth,
    position,
    accountNumber
  } = fieldNames;

  const getLabelText = (fieldName) => {
    switch (fieldName) {
      case firstName:
        return t('FIRST_NAME');
      case lastName:
        return t('LAST_NAME');
      case legalBusinessName:
        return t('LEGAL_BUSINESS_NAME');
      case email:
        return t('EMAIL');
      case mobileNumber:
        return t('MOBILE_NUMBER');
      case title:
        return t('TITLE');
      case plan:
        return t('PLAN');
      case term:
        return t('TERM');
      case offer:
        return t('OFFER');
      case eligibleEmployees:
        return t('ELIGIBLE_EMPLOYEES');
      case province:
        return t('PROVINCE');
      case preferredLanguage:
        return t('PREFERRED_LANGUAGE');
      case additionalEmployee:
        return t('ADDITIONAL_EMPLOYEE_TITLE');
        case accountNumber:
          return t('ACCOUNT_NUMBER');
      case noOfEmployees:
         return t('NO_OF_EMPLOYEE');
      case totalPerMonth:
          return t('PRODUCT_INFO_TOTAL_PER_MONTH');
      case position:
           return t('POSITION');
      default:
        return fieldName;
    }
  };
  
  return (
    <Box horizontal={3}>
      <ExpandCollapse
        tag="h2"
        open={['aboutYou', 'assignAnAdmin', 'productDetails']}
      >
        <ExpandCollapse.Panel id="aboutYou" header={t('SUMMARY_SECTION_ONE')}>
          <Box between={3}>
            {Object.keys(updatedStepData).map(
              (key) =>
                (
                  <Box between={2} key={`mainKey${key}`}>
                    <Heading level="h4">
                      {console.log('Updated', key)}
                      {getLabelText(key)} :{' '}
                     <NormalText>{updatedStepData[key]}</NormalText>
                    </Heading>
                  </Box>
                )
            )}
          </Box>
        </ExpandCollapse.Panel>
      </ExpandCollapse>
    </Box>
  );
};

Summary.propTypes = {
  stepData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Summary;
