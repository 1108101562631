import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from '@tds/core-tooltip'
import Text from '@tds/core-text'
import Field from '../Form/Field';

const CommonFields = ({ touched, errors, showTooltip, showMobilePhoneNote }) => {
  const [t] = useTranslation();
  const getTextLabel = (label) => {
    return `${label} *`
  }
  return (
    <>
      <Field
        name="firstName"
        label={getTextLabel(t('FIRST_NAME'))}
        touched={touched}
        errors={errors}
      />
      <Field
        name="lastName"
        label={getTextLabel(t('LAST_NAME'))}
        touched={touched}
        errors={errors}
      />
      <Field
        name="email"
        type="email"
        label={getTextLabel(t('EMAIL'))}
        touched={touched}
        errors={errors}
      />
      <Field
        name="mobileNumber"
        type="mask"
        label={getTextLabel(t('MOBILE_NUMBER'))}
        touched={touched}
        errors={errors}
      />
      <Field
        name="accountNumber"
        type="text"
        label={getTextLabel(t('ACCOUNT_NUMBER'))}
        tooltip={<Tooltip copy="en">{getTextLabel(t('ACCOUNT_NUMBER_TOOLTIP'))}</Tooltip>}
        touched={touched}
        errors={errors}
      />
        <Field
        name="position"
        type="text"
        label={getTextLabel(t('POSITION'))}
        touched={touched}
        errors={errors}
      />
    </>
  );
};



CommonFields.defaultProps ={
  showTooltip: true,
}
CommonFields.propTypes = {
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  showTooltip: PropTypes.bool,
};

export default CommonFields;
