import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { languageTypes } from '../../config/constants';

const logoUrls = {
  EN_LOGO: 'https://sof2-public-files.s3.ca-central-1.amazonaws.com/TELUS_Business_EN_Vert_2021_Digital_RGB.png',
  FR_LOGO:'https://sof2-public-files.s3.ca-central-1.amazonaws.com/TELUS_Affaires_FR_Vert_2021_Digital_RGB.png'
}
const localizedFileName = {
  [languageTypes.en]: logoUrls.EN_LOGO,
  [languageTypes.fr]: logoUrls.FR_LOGO
};

const Logo = ({ height }) => {
  const [t, i18next] = useTranslation();
  const logoImg = localizedFileName[i18next.language];

  return (
    <img style={{ height: height }} src={logoImg} alt={t('LOGO_ALT')} />
  );
};

Logo.propTypes = {
  height: PropTypes.string
};

Logo.defaultProps = {
  height: 'auto'
};

export default Logo;