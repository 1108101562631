import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '@tds/core-heading';
import Text from '@tds/core-text';
import Box from '@tds/core-box';
import styled from 'styled-components';
import Container from '../../components/Container';
import { scrollTop } from '../../utils';

const SuccessScreen = () => {
  const [t] = useTranslation();

  useEffect(() => {
    scrollTop();
  }, []);

  const CustomBox = styled(Box)`
    text-align: center;
  `;
  
  return (
    <Container>
      <CustomBox between={3} inset={3}>
        <Heading level="h2" style={{textAlign: 'center'}}>{t('THANK_YOU')}</Heading>
        <Text>
          <Text bold style={{textAlign: 'center'}}>{t('SUCCESS_PAGE.TEXT_1')}
          </Text> 
          <br />
          {' '}
          {t('SUCCESS_PAGE.TEXT_2')}{' '}
        </Text>
        <Text>
          <Text bold>{t('SUCCESS_PAGE.TEXT_3')}</Text>{' '}
          {t('SUCCESS_PAGE.TEXT_4')}&nbsp;
            &nbsp; 
          {t('SUCCESS_PAGE.TEXT_5')}
        </Text>
        <br />
        <Text>
          {t('SUCCESS_PAGE.TEXT_6')}
        </Text>
      </CustomBox>
    </Container>
  );
};

export default SuccessScreen;
