import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { languageTypes } from '../../config/constants';

const logoUrls = {
  EN_LOGO: 'https://klinik-files.s3.ca-central-1.amazonaws.com/MOBILE_KLINIK_logo.jpg',
  FR_LOGO:'https://klinik-files.s3.ca-central-1.amazonaws.com/MOBILE_KLINIK_BSRC_4C_F.jpg'
}
const localizedFileName = {
  [languageTypes.en]: logoUrls.EN_LOGO,
  [languageTypes.fr]: logoUrls.FR_LOGO
};

const Logo = ({ height, zIndex }) => {
  const [t, i18next] = useTranslation();
  const logoImg = localizedFileName[i18next.language];

  return (
    <img style={{ height: height, 'z-index': zIndex}} src={logoImg} alt={t('LOGO_ALT')} />
  );
};

Logo.propTypes = {
  height: PropTypes.string,
  zIndex: PropTypes.string
};

Logo.defaultProps = {
  height: 'auto',
  zIndex: 0
};

export default Logo;