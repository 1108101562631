import React from 'react';
import FlexGrid from '@tds/core-flex-grid';
import Box from '@tds/core-box';
import { HeaderBar, LogoBar } from '../../styles/header';
import { useLocation } from 'react-router-dom';
import Logo from './logo';
import { HeaderLinks } from '../../styles/elements';

const Footer = () => {

  const data = useLocation();
  const locale = data && data.pathname.split('/')[1];
  console.log('ldddddd', data.key);
  const getDimention = () => {
    return '250px';
  }

  return (
    <Box>
      <HeaderBar>
        <FlexGrid limitWidth>
          <FlexGrid.Row>
            <Box inset={3}>
            </Box>
            <HeaderLinks>
               <LogoBar>
                <Logo height={locale === 'en' ? '250px' : getDimention()} zIndex='-1' />
              </LogoBar>
            </HeaderLinks>
          </FlexGrid.Row>
        </FlexGrid>
      </HeaderBar>
    </Box>
  );
};

export default Footer;
