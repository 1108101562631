import React, { useState } from 'react';
import Box from '@tds/core-box';
import StepTracker from '@tds/core-step-tracker';
import { useTranslation } from 'react-i18next';

import Container from '../../components/Container';
import Form from '../../components/Form';

const RegistrationForm = () => {
  const [t] = useTranslation();
  const [step, setStep] = useState(0);

  return (
    <Container>
      <div style={{marginBottom: '25px'}}>
          <StepTracker
            copy="en"
            current={step}
            steps={[t('STEP_1'), t('STEP_4')]}
          />
      </div>
      <Box between={3}>
        <Form step={step} setStep={setStep} />
      </Box>
    </Container>
  );
};

export default RegistrationForm;
