import React, { useEffect } from 'react';
import CSSReset from '@tds/core-css-reset';
import PropTypes from 'prop-types';
import { withRouter, Route } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';
import i18n from '../../config/i18n';
import LandingPage from '../../Pages/LandingPage';
import RegistrationForm from '../../Pages/RegistrationForm';
import SuccessScreen from '../../Pages/successScreen';

function App({
  match: {
    params: { locale },
  },
}) {
  useEffect(() => {
    i18n.changeLanguage(locale);
    document.documentElement.lang = locale;
  }, [locale]);

  return (
    <>
      <CSSReset />
      <Header />
      <Route exact path="/:locale/" component={LandingPage} />
      <Route path="/:locale/registration-form" component={RegistrationForm} />
      <Route path="/:locale/success" component={SuccessScreen} />
      <Footer />
    </>
  );
}

App.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      locale: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(App);
