import React, { useEffect, useState } from 'react';
import './styles.css';
import {
  TermsAndConditions,
} from '../../styles/elements';
import LoadingBar from 'react-top-loading-bar';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');
const contentful = require('contentful');
const spaceId = "p6zhcvwfjk5x";
const accessToken = "KvC9WgOGinieN36FeF_aU-4fMDnME1TdH_cXTI4QguY";
const environment = "dev";
const entryId = "7aqtwOmQGTFbY5mQs2OyaT";

const client = contentful.createClient({
  space: spaceId,
  accessToken: accessToken,
  environment: environment
});
const generateHtml =  (termsConditionText) => {
  const document = {
    content: termsConditionText.content,
    nodeType: termsConditionText.nodeType
  };
  const getHtml = documentToHtmlString(document);
  return getHtml;
};
const getTermsCondition =  (locale) => {
  const getLocale = {
    EN: 'en-US',
    FR: 'fr-CA'
  };
  return client.getEntry(entryId, { locale: getLocale[locale] }).then(response => {
    return generateHtml(response.fields.termsConditionText);
  }).then(renderedHtml => {
    document.getElementById("loader-button").click();
    document.getElementById('termsAndConditions').innerHTML = renderedHtml;
  });

};
const Terms = () => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    getTermsCondition('FR');
  }, [])
  return (
    <TermsAndConditions>
      <div>
      <LoadingBar
        color='#2B8000'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <button style={{display: 'none'}} id="loader-button" onClick={() => setProgress(100)}></button>
      <br />
    </div>
      <div className='agreement' id="termsAndConditions">
      </div>
    </TermsAndConditions>
  );
};

export default Terms;
